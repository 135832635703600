
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Mutual } from "@/core/model/mutual";
import ToastService from "@/core/services/ToastService";

export default defineComponent({
  name: "admin-add-mutual-modal",
  emit: ["update-success"],
  components: {},
  props: {
    action: { type: String, default: "new" },
    mutual: { type: Object, default: {} as Mutual },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const addMutualModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingLocation = ref<boolean>(false);
    const options = ref<Array<Location>>([]);
    const formData = ref({
      mutual: "",
      name: "",
      cuit: "",
      erp_code: "",
      billing_condition: "",
      phone: "",
      email: "",
      address: "",
      location: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 100,
          message: "Este campo admite máximo 100 caracteres",
          trigger: "change",
        },
      ],
      cuit: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 15,
          message: "Este campo admite máximo 15 caracteres",
          trigger: "change",
        },
      ],
      erp_code: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 30,
          message: "Este campo admite máximo 30 caracteres",
          trigger: "change",
        },
      ],
      billing_condition: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 255,
          message: "Este campo admite máximo 255 caracteres",
          trigger: "change",
        },
      ],
      phone: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        /*{
          type: 'number',
          message: "Este campo sólo admite números",
          trigger: "change",
        },*/
        {
          max: 30,
          message: "Este campo admite máximo 30 caracteres",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Ingrese un email válido",
          trigger: "change",
        },
        {
          max: 255,
          message: "Este campo admite máximo 255 caracteres",
          trigger: "change",
        },
      ],
      location: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 100,
          message: "Este campo admite máximo 100 caracteres",
          trigger: "change",
        },
      ],
    });

    let interval = 0;
    onMounted(() => {
      if (props.action == "edit") {
        interval = setInterval(function () {
          if (loadfFormData()) {
            clearInterval(interval);
          }
        }, 500);
      }
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(
              props.action == "new"
                ? Actions.ADMIN_MUTUAL_CREATE
                : Actions.ADMIN_MUTUAL_UPDATE,
              formData.value
            )
            .then(() => {
              loading.value = false;
              if (store.state.AdminMutualesModule.success) {
                emit("update-success");
                ToastService.show("Mutual registrada con éxito", "success");
                hideModal(addMutualModalRef.value);
                if (props.action == "new") {
                  setTimeout(() => {
                    router.push({
                      name: "admin_mutual_profile",
                      params: { uuid: store.state.AdminMutualesModule.mutual.uuid },
                    });
                  }, 500);
                }
              }
            })
            .catch(() => {
              let message = "";
              if (typeof store.state.AdminMutualesModule.errors === "object") {
                message = Object.values(store.state.AdminMutualesModule.errors).join(
                  "<br>"
                );
              } else if (Array.isArray(store.state.AdminMutualesModule.errors)) {
                message = store.state.AdminMutualesModule.errors.join("<br>");
              } else {
                message = store.state.AdminMutualesModule.errors;
              }
              loading.value = false;
              Swal.fire({
                text: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Intenta de nuevo!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Continuar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const loadLocation = (query) => {
      if (query !== "") {
        const params = {
          page_number: 1,
          page_size: 100,
          filter_loc_type_name: "Ciudad",
          search: query,
          sort_order: "asc",
          sort_field: "name",
        };
        loadingLocation.value = true;
        store.dispatch(Actions.ADMIN_LOCATION_LIST, params).then(() => {
          options.value = store.state.LocationModule.list.items;
          loadingLocation.value = false;
        });
      } else {
        options.value = [];
      }
    };

    const loadfFormData = () => {
      if (props.mutual.name !== undefined) {
        formData.value.name = props.mutual.name;
        formData.value.cuit = props.mutual.cuit;
        formData.value.erp_code = props.mutual.erp_code;
        formData.value.billing_condition = props.mutual.billing_condition;
        formData.value.phone = props.mutual.phone;
        formData.value.email = props.mutual.email;
        formData.value.address = props.mutual.address;
        formData.value.location = props.mutual.location.uuid;
        options.value.push(props.mutual.location);
        if (props.action == "edit") {
          formData.value.mutual = props.mutual.uuid;
        }

        return true;
      }
      return false;
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addMutualModalRef,
      options,
      loadLocation,
      loadingLocation,
    };
  },
});
